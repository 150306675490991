import React from 'react';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import HomeLogos from '../components/HomeLogos';
import DigitalContentList from '../components/DigitalContentList';
import DigitalDMSection from '../components/DigitalDMSection';

export default () => (
  <Layout current="scens" subCurrent="digitalmarketing">
    <CommonPageBanner
      title="数字化营销"
      description="数字化营销的发展基于业务的数字化和数据的治理需求，但真正实现用数字化营销转型的企业却凤毛麟角。Convertlab认为数字化营销驱动的增长是爆发式、自驱式和高效能的增长，在长期的数字化营销实践中，将与您的企业一起探索数字营销背后隐藏的潜藏机遇和成功因子。"
      imgName="digital"
    />
    <DigitalDMSection />
    <DigitalContentList />
    <HomeLogos />
  </Layout>
);
