import React from 'react';
import { Col } from 'antd';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import TitledVerticalBoxes from './TitledVerticalBoxes';
import Dm1 from '../images/dm-1.svg';
import Scen4 from '../images/scen-4.svg';
import Scen5 from '../images/scen-5.svg';
import Scen6 from '../images/scen-6.svg';
import Scen7 from '../images/scen-7.svg';
import VerticalBox from './VerticalBox';

const DIGITAL_DATA = [
  {
    id: 'scrm-1',
    iconIndex: 39,
    description: '微信/企业微信营销',
  },
  {
    id: 'scrm-2',
    iconIndex: 38,
    description: '短信/彩信营销推送',
  },
  {
    id: 'scrm-3',
    iconIndex: 48,
    description: '自动化邮件营销',
  },
  {
    id: 'scrm-4',
    iconIndex: 49,
    description: 'App消息推送',
  },
  {
    id: 'scrm-5',
    iconIndex: 33,
    description: '定向广告推送',
  },
  {
    id: 'scrm-6',
    iconIndex: 50,
    description: '小程序营销推广',
  },
];

export default () => {
  return (
    <div className="content-list-wrapper scen-content">
      <section className="image-text-section">
        <Container>
          <ImageText image={Scen4} imgWidth={435} imgHeight={326} title="营销的数字化管理体系" imageSize={11}>
            <ImageTextDescription label="全面数字化与目标客户及受众群体的触点，建立数字化链接" />
            <ImageTextDescription label="对非数字化的营销触点进行数字化升级（例如线下活动）" />
            <ImageTextDescription label="打通广告投放渠道和落地触点，实现流量的链路数字化" />
            <ImageTextDescription label="打通交易平台和触点，从POS、二维码到电商平台、线下门店" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section mini-padding">
        <Container>
          <ImageText image={Scen5} imgWidth={466} imgHeight={368} title="客户数据的数字化治理" position="right" imageSize={11}>
            <ImageTextDescription label="全渠道客户数据的汇总、管理、识别与自动合并" />
            <ImageTextDescription label="定义客户生命周期模型，自动计算客户生命周期阶段" />
            <ImageTextDescription label="数据的多维度标签体系，自动化智能化打标签" />
            <ImageTextDescription label="通过AI智能数据模型进行数据挖掘，形成精准用户画像" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section blue mini-padding">
        <Container>
          <ImageText image={Scen6} imgWidth={435} imgHeight={418} title="数字化营销洞察和分析" imageSize={11}>
            <ImageTextDescription label="洞察客户群体的状态、人群特征和时空分布" />
            <ImageTextDescription label="分析客户群体的增加与流失，掌握核心及长尾用户的" />
            <ImageTextDescription label="智能化分析哪些渠道或营销手段的拉新、留存和转化更好" />
            <ImageTextDescription label="智能化洞察客户购买频次、购买偏好和购买动机" />
          </ImageText>
        </Container>
      </section>
      <TitledVerticalBoxes title="全渠道消费者互动" shadowed>
        {DIGITAL_DATA.map((item) => (
          <Col lg={8} md={8} sm={8} xs={12} className="icon-text-col same" key={item.id}>
            <VerticalBox {...item} />
          </Col>
        ))}
      </TitledVerticalBoxes>
      <section className="image-text-section">
        <Container>
          <ImageText image={Scen7} imgWidth={502} imgHeight={343} title="智能化营销运营、优化、预测和决策" position="right" imageSize={11}>
            <ImageTextDescription label="围绕关键营销时刻（Moment of Truth）的自动化营销流程" />
            <ImageTextDescription label="客户旅程（Customer Journey）的智能化可视化设计和执行" />
            <ImageTextDescription label="Always on的全天候营销策略，7x24小时不间断营销" />
            <ImageTextDescription label="Ad Hub智能化广告投放和效果优化管理" />
            <ImageTextDescription label="AI智能商品推荐，实现产品/商品与用户/消费者的完美匹配" />
            <ImageTextDescription label="帮助用户建立基于大数据、云计算和AI的营销决策体系" />
          </ImageText>
        </Container>
      </section>
      <section className="image-text-section">
        <Container>
          <ImageText image={Dm1} imgWidth={413} imgHeight={347} title="AI Hub赋能智能化营销" imageSize={11}>
            <ImageTextDescription label="AI智能营销圈群和聚类分析，有效提升CVR和CTR" />
            <ImageTextDescription label="NBA（Next Best Action）智能营销沟通策略有效提升RFM" />
            <ImageTextDescription label="智能推荐系统，为多场景提供精准的商品推荐和人群匹配" />
            <ImageTextDescription label="智能化广告投放管理，实时人群决策引擎提升广告ROI" />
          </ImageText>
        </Container>
      </section>
    </div>
  );
};
