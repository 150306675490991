import React from 'react';
import { Button } from 'antd';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import DmSection from '../images/dm-section.svg';
import { useSearch } from '../utils/common';

export default () => {
  const search = useSearch();
  return (
    <section className="digital-dm-section">
      <Container>
        <ImageText
          image={DmSection}
          title="Convertlab一体化营销云"
          title2="数字化营销的经典增长方案"
          position="right"
          imgWidth={517}
          imgHeight={340}
          detailButton={<Button><a href={`/marketingcloud/${search}`}>了解详情</a></Button>}
        >
          <ImageTextDescription label="数字化营销的核心是能够进行大规模的精准个性化营销，需要具备面向庞大客户群体的整体营销能力，需要有千人千面的个性化精准营销能力，尤其是当营销活动涉及到不同区域、不同渠道和不同商品品类时，这样的挑战尤为艰巨。" />
          <ImageTextDescription label="Convertlab一体化营销云从数字化链接、数据管理和洞察到全渠道消费者互动、自动化智能营销以及敏捷营销实践，助力企业建立从方法论到实践落地的“数据驱动增长体系”，真正实现数字化营销增长模式。" />
        </ImageText>
      </Container>
    </section>
  );
};
